import React, { useState, useRef, MouseEvent, TouchEvent } from "react";
import { useSpring, animated, config } from "react-spring";

import { Button, ButtonProps } from "Atoms";

const AnimatedButton = animated(Button);

type Props = {
    customOnClick: (e: MouseEvent | TouchEvent) => void;
    onLongPress?: () => void;
    setShouldShowInfoIcon?: (shouldShowInfoIcon: boolean) => void;
} & ButtonProps;

export const SpringButtonWithLongPress: React.FC<Props> = ({
    children,
    customOnClick,
    style,
    onLongPress,
    setShouldShowInfoIcon,
    ...restProps
}) => {
    const [pressed, setPressed] = useState(false);
    const isLongPress = useRef(false);
    const timeoutRef = useRef<number | null>(null);
    const shortDelayTimeoutRef = useRef<number | null>(null);
    const startYRef = useRef(0);
    const movedRef = useRef(false);
    const longPressTriggeredRef = useRef(false);

    const delay = 500; // Long press delay in ms

    const movementThreshold = 10 * window.devicePixelRatio + window.innerHeight * 0.002;

    const clearTimers = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
        if (shortDelayTimeoutRef.current) {
            clearTimeout(shortDelayTimeoutRef.current);
            shortDelayTimeoutRef.current = null;
        }
        setShouldShowInfoIcon?.(false);
        longPressTriggeredRef.current = false;
    };

    const handleStartPress = (e: React.PointerEvent) => {
        setPressed(true);
        startYRef.current = e.clientY;
        movedRef.current = false;
        isLongPress.current = false;
        longPressTriggeredRef.current = false;

        timeoutRef.current = window.setTimeout(() => {
            if (!movedRef.current) {
                isLongPress.current = true;

                setPressed(false);
                onLongPress?.();
            }
        }, delay);

        shortDelayTimeoutRef.current = window.setTimeout(() => {
            if (!movedRef.current) {
                setShouldShowInfoIcon?.(true);
                longPressTriggeredRef.current = true;
            }
        }, 150);
    };

    const handleMove = (e: React.PointerEvent) => {
        const y = e.clientY;
        if (Math.abs(y - startYRef.current) > movementThreshold) {
            movedRef.current = true;
            pressed && setPressed(false);
            clearTimers();
        }
    };

    const handleEndPress = (e: React.PointerEvent) => {
        if (!movedRef.current && !isLongPress.current && !isDisabled) {
            customOnClick?.(e);
        }
        resetTimerAndPressed();
    };

    const resetTimerAndPressed = () => {
        clearTimers();
        setPressed(false);
    };

    //@ts-ignore
    const { scale } = useSpring({
        native: true,
        from: { scale: 1 },
        to: { scale: pressed ? 0.9 : 1 },
        config: {
            wobly: config.wobbly,
            mass: 0.4,
            tension: 650
        }
    });

    //TODO: fix the ts ignore here, which has been introduced

    const isDisabled = restProps.isDisabled;

    return (
        <>
            {/** @ts-ignore */}
            <AnimatedButton
                _hover={undefined}
                transition={undefined}
                style={{
                    ...style,
                    touchAction: "pan-y",
                    transform: scale.interpolate((scale: number) => `scale(${scale})`)
                }}
                onPointerDown={(e: React.PointerEvent) => {
                    e.preventDefault();
                    !isDisabled && handleStartPress(e);
                }}
                onPointerMove={(e: React.PointerEvent) => {
                    e.preventDefault();
                    handleMove(e);
                }}
                onPointerUp={(e: React.PointerEvent) => {
                    e.preventDefault();
                    handleEndPress(e);
                }}
                onTouchEnd={(e: TouchEvent) => {
                    resetTimerAndPressed();
                }}
                {...restProps}
            >
                {children}
            </AnimatedButton>
        </>
    );
};
