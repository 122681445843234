import React from "react";
import swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";
import ReactDOMServer from "react-dom/server";

import { Box, Flex, Stack } from "Atoms";

export const infoNotification = async (message: string, html: React.ReactElement) => {
    return await swal({
        title: message,
        html: ReactDOMServer.renderToStaticMarkup(html),
        position: "center",
        type: "info",
        heightAuto: false
    });
};

export type InputOptions = { [key: string]: { inputLabel: string; defaultChecked: boolean } };

export const confirmNotificationWithCheckBoxes = async (
    title: string,
    text: string,
    inputOptions: InputOptions,
    confirmButtonText = "Ja",
    confirmButtonColor = "#d33",
    cancelButtonText = "Avbryt",
    type = "warning",
    position = "center",
    showCloseButton = false,
    showCancelButton = true,
    cancelButtonColor = "#d33",
    reverseButtons = false
): Promise<SweetAlertResult> => {
    const checkboxes = (
        <Box>
            {Object.entries(inputOptions).map(([key, value]) => {
                return (
                    <Box key={key} mt={2}>
                        <input
                            type="checkbox"
                            id={key}
                            defaultChecked={value.defaultChecked}
                            height={"1rem"}
                            width={"1rem"}
                        />
                        <Box as="span" pl={2}>
                            {value.inputLabel}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
    const html = ReactDOMServer.renderToString(checkboxes);
    return await swal({
        title,
        html,
        text,
        position,
        type,
        confirmButtonColor,
        confirmButtonText,
        cancelButtonText,
        showCloseButton,
        showCancelButton,
        cancelButtonColor,
        heightAuto: false,
        reverseButtons,
        preConfirm: () => {
            const checkboxValues: { [key: string]: boolean } = {};
            Object.keys(inputOptions).forEach(key => {
                const checkbox = (swal as any).getPopup().querySelector("#" + key);
                checkboxValues[key] = checkbox.checked;
            });
            return checkboxValues;
        }
    } as SweetAlertOptions).then(result => {
        return result;
    });
};
export const confirmNotificationWithDate = async (
    title: string,
    text: string,
    datePickerLabel?: string,
    startDate?: Date | null,
    confirmButtonText = "Ja",
    confirmButtonColor = "#38A169",
    cancelButtonText = "Avbryt",
    type = "warning",
    position = "center",
    showCloseButton = false,
    showCancelButton = true,
    cancelButtonColor = "#d33",
    reverseButtons = false
): Promise<SweetAlertResult> => {
    const dateInput = (
        <Stack>
            <Box>{text}</Box>
            <Flex
                justifyContent="space-evenly"
                alignItems="baseline"
                verticalAlign="middle"
                mt={2}
            >
                {(datePickerLabel &&
                    <Box fontWeight={"bold"}                    >
                        {datePickerLabel}
                    </Box>
                )}
                <Box mt={2}>
                    <input
                        type="date"
                        id="selectedDate"
                        defaultValue={(startDate || new Date()).toISOString().substring(0, 10)}
                    />
                </Box>
            </Flex>
        </Stack>
    );

    const html = ReactDOMServer.renderToString(dateInput);

    return swal({
        title,
        text,
        html,
        position,
        type,
        confirmButtonColor,
        confirmButtonText,
        cancelButtonText,
        showCloseButton,
        showCancelButton,
        cancelButtonColor,
        heightAuto: false,
        reverseButtons,
        preConfirm: () => {
            const dateElement = (swal as any).getPopup().querySelector("#selectedDate") as HTMLInputElement;
            return dateElement?.value;
        }
    } as SweetAlertOptions).then(result => {
        return result;
    });
};